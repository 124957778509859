import { memo } from 'react';
import {
  ArrowDownTrayIcon,
  ArrowPathRoundedSquareIcon
} from '@heroicons/react/20/solid';
import { PaintBrushIcon, ShoppingBagIcon } from '@heroicons/react/24/outline';
import { Transition } from '@headlessui/react';

const ActionPanelComp = ({
  render,
  imgURL,
  editMode,
  setActiveInput,
  subscription,
  openPricingModal,
  handleDownload,
  editable,
  handleEditModeChange,
  stylesMap,
  counter,
  advancedMode,
  hijackAdvancedEditMode,
  hideIcons = false
}) => {
  return (
    <Transition
      show={!editMode}
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        className={`mt-2 flex px-4 items-center  text-base font-medium text-gray-900 transition-all flex-row justify-between`}
      >
        <div className="flex flex-col gap-2">
          <div>{render?.room_type}</div>
          <div className="max-w-fit">
            <div className="bg-neutral-100 rounded-md">
              <span
                aria-hidden="true"
                className="text-xs font-bold gradient-text bg-[#ECEBF9] pl-2 pr-2 p-0.5 rounded-sm line-clamp-1"
                style={{ textTransform: 'capitalize' }}
              >
                {stylesMap[render?.style?.toLowerCase()] ??
                  render?.style ??
                  'Default Style'}
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-row space-x-2 lg:space-x-4 items-center">
          {(counter > 0 && !render?.output_url) || advancedMode ? (
            <span className="text-[#4138C2] font-medium text-sm">
              {Math.round((counter / 200) * 100)}%
              {counter < 150 ? (
                <span> Processing</span>
              ) : (
                <span> Finishing up..</span>
              )}
            </span>
          ) : null}

          {editable &&
          render?.output_url &&
          !advancedMode &&
          !hideIcons &&
          false ? (
            <span
              className="shadow-sm rounded-md p-2 cursor-pointer"
              onClick={() => hijackAdvancedEditMode(render)}
            >
              <PaintBrushIcon className="w-6 text-gray-500" />
              dd
            </span>
          ) : null}

          {render?.output_high_url ? (
            <p className="mt-1  text-xs font-bold text-white bg-indigo-800 rounded p-2">
              HD
            </p>
          ) : null}

          {!editMode && !advancedMode ? (
            <a
              className="shadow-sm rounded-md p-2 cursor-pointer"
              // onClick={() => setActiveInput(imgURL, render?.aspect_ratio)}
              href={`https://lens.google.com/uploadbyurl?url=${imgURL}`}
              target="_blank"
            >
              <ShoppingBagIcon className="w-6 text-gray-500" />
            </a>
          ) : null}
          {setActiveInput && !editMode && !advancedMode ? (
            <span
              className="shadow-sm rounded-md p-2 cursor-pointer"
              onClick={() => setActiveInput(imgURL, render?.aspect_ratio)}
            >
              <ArrowPathRoundedSquareIcon className="w-6 text-gray-500" />
            </span>
          ) : null}
          {subscription &&
          render?.output_url &&
          !editMode &&
          !advancedMode &&
          !hideIcons ? (
            <span
              className="shadow-sm rounded-md p-2 cursor-pointer"
              onClick={(e) => handleDownload(e, render, imgURL)}
            >
              <ArrowDownTrayIcon className="w-6 text-gray-500" />
            </span>
          ) : (
            !editMode &&
            render?.output_url &&
            !hideIcons && (
              <span
                className="shadow-sm rounded-md p-2 cursor-pointer"
                onClick={() => {
                  openPricingModal();
                }}
              >
                <ArrowDownTrayIcon className="w-6 text-gray-500" />
              </span>
            )
          )}
        </div>
      </div>
      <p className="mt-1 px-5 text-sm text-gray-500">{render?.redesignType}</p>
    </Transition>
  );
};

const ActionPanel = memo(ActionPanelComp);

export default ActionPanel;
